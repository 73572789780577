.notifications {
  padding: 12px 24px;
  transition: 0.4s ease-in-out;
  border-bottom: solid 1px var(--color-grey-light);
}

.disabled {
  opacity: 0.5;
  cursor: wait;
}

.read:not(.disabled) {
  cursor: default;
}

.notifications:not(.read):not(.disabled) {
  cursor: pointer;
}

.notifications:hover {
  background-color: var(--color-grey-light);
}

.notifications:last-child {
  border: none;
}
