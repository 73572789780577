.link {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.71;
  text-decoration: none;
  cursor: pointer;
}

.block {
  display: block;
}

.active {
  color: var(--color-primary-light);
}

.disabled {
  color: var(--color-grey-dark);
}
