.buttons {
  position: fixed;
  bottom: 40px;
  right: 28px;
}

.button {
  color: var(--color-grey);
  border: none;
  transition: all 0.1s ease-in-out;
}

.selected {
  color: var(--color-primary);
}
