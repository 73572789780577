.container {
  width: 100vw;
  display: grid;
  grid-template-columns: 57% 43%;
  grid-template-rows: 100%;
  overflow: hidden;
}

.button {
  margin: 32px 0 17px 0;
}

.signUp {
  text-align: center;
}

.signupTitle {
  margin-bottom: 12px;
}

.signupSubtitle {
  margin-bottom: 33px;
}

.signupText {
  line-height: 1.71;
}

.signupText div:first-of-type {
  margin-bottom: 4px;
}

.signupContactUs {
  margin-top: 25px;
  text-align: center;
}

.nextButton {
  margin-top: 24px;
  margin-bottom: 16px;
}

.descriptions {
  line-height: 1.71;
}

.resetPasswordOption {
  margin-bottom: 16px;
}

.passwordInput {
  margin-bottom: 8px;
}

.forgotPassword {
  display: flex;
  cursor: pointer;
  color: var(--color-primary-light);
  text-decoration: none;
}

.acceptTermsCheckbox {
  display: flex;
  align-items: flex-start;
}

.acceptTermsCheckbox > label {
  margin-top: 4px;
}

.acceptTermsCheckbox > div {
  margin-left: 8px;
}

.buttonSuccess {
  margin: 32px auto !important;
}

/*Integration Login Styles*/

.webview_container {
  background: linear-gradient(to right, var(--color-primary-light), var(--color-primary-green));
  width: 100vw;
  overflow: hidden;
}

.webview_container_form {
  width: 70%;
  height: 80%;
  min-width: 300px;
  min-height: 600px;
  max-width: 500px;
  max-height: 800px;
  border-radius: 25px;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  padding: 2% 2% 2% 2%;
  overflow: auto;
}

/*Signup Styles*/

.termsMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2%;
  margin-right: 2%;
  text-align: left;
}

.signUpResult {
  top: 50%;
  left: 50%;
  max-width: 90%;
  width: fit-content;
  height: fit-content;
  position: fixed;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  background-color: #ffffff;
  padding: 3% 3% 3% 3%;
}
