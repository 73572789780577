.button {
  margin: 32px 0 17px 0;
}

.brand {
  left: 50%;
  position: fixed;
  transform: translate(-50%, 40%);
}

.cardTitle {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--color-primary);
  font-weight: 300;
  font-size: 18px;
  line-height: 1.33;
  color: var(--color-primary-light);
}

.signUp {
  text-align: center;
}

.descriptions {
  line-height: 1.71;
}

.warning {
  color: var(--color-error);
  margin-right: 8px;
}

.eduAccountsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 8%;
}

.eduAccountsCard {
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.nextButton {
  margin-top: 24px;
  margin-bottom: 16px;
}

.webview_container_form {
  width: 70%;
  height: 80%;
  min-width: 300px;
  min-height: 600px;
  max-width: 500px;
  max-height: 800px;
  border-radius: 25px;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  padding: 2% 2% 2% 2%;
  overflow: auto;
}

.login_form {
  width: 80%;
  display: grid;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -45%);
}

.reset_form {
  width: 90%;
  display: grid;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

.eduChooseAccountTitle {
  font-size: x-large;
  text-align: center;
}

.eduAccountDialogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  margin-top: 26%;
  margin-bottom: 4%;
}
