.subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  color: var(--color-primary-light);
  margin: 0 0 8px 0;
}

.error {
  color: var(--color-error);
}
