.title {
  font-weight: 200;
  font-size: 32px;
  line-height: 1;
  color: transparent;
  background: var(--color-primary-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  text-transform: lowercase;
  width: fit-content;
}
