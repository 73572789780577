.labeledInput {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: solid 1px var(--color-grey);
  padding: 8px 8px 8px 8px;
  border-radius: var(--border-default);
  overflow: hidden;
}

.errorMessage {
  height: 16px;
  margin: 4px 0 0 0;
  font-size: 13px;
  color: var(--color-error);
}

.error {
  border: solid 1px var(--color-error);
}