.menuItemLink {
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-primary);
  text-decoration: none;
  display: inline-block;
  border-bottom: solid 1px var(--color-grey-light);
  padding: 8px;
}

.menuItemLink:focus {
  outline: none;
}

.activeItem {
  font-weight: 600;
}
