.section {
  margin: 0 auto;
}

.warning {
  color: var(--color-error);
  font-weight: 700;
}

.quick-links {
  cursor: pointer;
}
