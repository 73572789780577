.heading {
  margin: 0;
}

.brand {
  width: 156px;
  height: 44px;
}

.link {
  cursor: pointer;
}
