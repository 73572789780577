.subtitle {
  margin-bottom: 32px;
}

.statusBadge {
  display: block;
  width: fit-content;
}

.organizationData {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.organizationInfo {
  flex-grow: 1;
  margin-left: 16px;
}