.container {
  width: 100%;
  max-width: 1330px;
  margin: 0 auto;
}

.title {
  padding: 24px 0 16px 0;
  border-bottom: solid 1px var(--color-grey-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deviceInfo {
  color: var(--color-primary-light);
  margin-right: 8px;
  font-size: 16px;
  font-weight: 600;
}

.card {
  width: 25%;
}
