.iconMore {
  width: 40px;
  height: 40px;
  text-align: center;
  margin-left: 5px;
  border-radius: 50%;
  border: 2px solid var(--color-grey-light);
  color: var(--color-grey-dark);
  line-height: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}
