.tag {
  color: var(--color-primary);
  background: var(--color-grey-light);
  border-radius: 16px;
  padding: 8px 16px;
  display: inline-block;
  font-size: 13px;
  line-height: 1.23;
  margin: 16px 0 0 0;
  border: none;
  outline: inherit;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.tag:hover {
  background-color: var(--color-grey);
}

.selected {
  background-color: var(--color-success);
  color: var(--color-white);
}
