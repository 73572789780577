.cardTitle {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--color-primary);
  font-weight: 300;
  font-size: 18px;
  line-height: 1.33;
  color: var(--color-primary-light);
}