.memberList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px 24px;
}

/* fallback IE11 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .memberList {
    display: block;
  }

  .memberList > * {
    display: inline-block;
    width: 30%;
    margin-left: 3%;
    margin-bottom: 40px;
  }
}

.memberHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emptyList {
  border-radius: 4px;
  border: solid 1px var(--color-grey);
  padding: 50px;
  text-align: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.footer > div {
  padding-bottom: 0;
}
