.modalBody {
  height: 50vh;
  overflow: auto;
}

.summary {
  display: flex;
  margin-left: 20px;
}

.summary :nth-child(2) {
  margin-left: 5px;
}

.totalImportedLines {
  display: flex;
  margin-left: 40px;
}

.totalImportedLines :nth-child(2) {
  margin-left: 5px;
}

.totalImportedLines :nth-child(3) {
  margin-left: 5px;
}

.resultLists {
  display: flex;
  margin-left: 60px;
}

.resultLists :nth-child(2) {
  margin-left: 5px;
}

.errorList {
  display: flex;
  margin-left: 80px;
}

.errorList :nth-child(2) {
  margin-left: 5px;
}

.errorList div {
  margin-right: 1px;
}

.resultListsButton {
  margin-left: 43px;
}
