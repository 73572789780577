.statusBadge {
  display: block;
  width: fit-content;
}

.statusContainer {
  margin-bottom: 8px;
}

.statusContainer > div {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.statusContainer > div > p {
  font-size: 12px;
  color: var(--color-primary);
  margin-right: 16px;
}