.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.qr-code {
  text-align: center;
}

.qr-code img {
  width: 50%;
}
