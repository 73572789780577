.updateProfileLink {
  margin-bottom: 14px;
}

.curve {
  position: absolute;
  top: 28px;
  left: -68px;
  width: auto;
  height: 200px;
}
