.breadcrumb {
  text-decoration: none;
  font-size: 14px;
  line-height: 2;
  color: var(--color-grey-dark);
}

.active {
  font-weight: 500;
  color: var(--color-primary-light);
}
