.loader {
  display: flex;
  justify-content: center;
}

.loader div {
  height: 15px;
  width: 5px;
  margin: 2px;
  background: var(--color-white);
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
  border-radius: 3px;
}

.loader div:nth-child(1) {
  animation-delay: -1.1s;
}

.loader div:nth-child(2) {
  animation-delay: -1s;
}

.loader div:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
