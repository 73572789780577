.toggleMember {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 4px 12px;
  transition: var(--transition-default);
}

.toggleMember:hover {
  background-color: var(--color-grey-light);
}

.profileCheckbox {
  display: flex;
  align-items: center;
}

.profileCheckboxAvatar {
  margin-right: 12px;
}

.profileCheckboxWrapper {
  border: solid 1px var(--color-grey);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  max-height: 333px;
  overflow-y: scroll;
}
