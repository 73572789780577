.error {
  background-color: var(--color-error);
}

.warning {
  background-color: var(--color-alert);
}

.success {
  background-color: var(--color-success);
}

.icon {
  fill: var(--color-white);
  height: 16px;
}

.icon:hover {
  fill: var(--color-white);
}
