:root {
  --font-default: 'Nunito', sans-serif;
  --color-primary: #002f72;
  --color-primary-dark: #110e2d;
  --color-primary-light: #0080e5;
  --color-primary-hover: #2262bd;
  --color-primary-green: #00d7a4;
  --color-primary-gradient: linear-gradient(to right, var(--color-primary-light), var(--color-primary-green));

  --color-secondary-hover: #f1f4f8;
  --color-secondary-disabled: #bababa;

  --color-white: #fff;
  --color-white-secondary: #fafafa;

  --color-black: #212121;
  --color-black-secondary: #424242;

  --color-grey: #d5d5d5;
  --color-grey-light: #ededf0;
  --color-grey-dark: #888696;

  --color-table-header: #f2f2f2;

  --color-support-light: #f8f8f8;

  --color-secondary-grey-light: #7e8488;
  --color-secondary-grey-dark: #4c4b56;

  --color-success: #01d6a5;
  
  --color-alert: #fab200;
  --color-alert-dark: #cc9306;
  
  --color-warning: #fab200;
  --color-warning-dark: #cc9306;

  --color-error: #f84949;
  --color-error-dark: #cc1818;

  --border-default: 40px;
  --border-half: 20px;
  --border-circle: 100%;

  --transition-default: all 0.2s ease-in-out;
  --transition-slow: all 0.3s linear;

  --shadow-default: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
