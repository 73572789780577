.badge {
  padding: 8px 10px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
}

.default {
  background-color: var(--color-white);
  color: var(--color-grey-dark);
  border: solid 1px var(--color-grey);
}

.primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
}

.success {
  background-color: var(--color-success);
  color: var(--color-white);
  border: none;
}

.alert {
  background-color: var(--color-alert);
  color: var(--color-white);
  border: none;
}

.danger {
  background-color: var(--color-error);
  color: var(--color-white);
  border: none;
}

.blocked {
  background-color: var(--color-black-secondary);
  color: var(--color-white);
  border: none;
}

.pending {
  background-color: var(--color-grey-light);
  border: none;
}
