.sectionFilters {
  margin-top: 46px;
}

.noLogsFound {
  color: var(--color-grey-dark);
}

.tableFooter {
  display: flex;
  flex-direction: row;
  align-items: top;
  padding: 0px 12px;
}

.nLoad {
  color: var(--color-grey-dark);
  margin: 0px;
}

.tableFooterLeft, .tableFooterRight {
  flex: 1;
  padding: 7px 0px;
}
