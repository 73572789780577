.protocolIcon {
  margin-right: 8px;
}

.acquisitionLink {
  cursor: pointer;
  color: var(--color-primary-hover);
  transition: color 200ms ease;
  font-weight: 500;
}

.acquisitionLink:hover {
  color: var(--color-primary-light);
}
