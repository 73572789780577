.contentWrapper {
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
}

.contentWrapper > * {
  margin-right: 16px;
}

.contentWrapper:last-child {
  margin-right: initial;
}

.confirmShareButton {
  display: block;
  margin: 0 0 0 auto;
}
