.statusFlag {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 16px 0 0 0;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-top: 20px solid var(--color-error);
  border-left: 20px solid var(--color-error);
}

.danger {
  border-top: 20px solid var(--color-error);
  border-left: 20px solid var(--color-error);
}

.secondary {
  border-top: 20px solid var(--color-grey-dark);
  border-left: 20px solid var(--color-grey-dark);
}

.dark {
  border-top: 20px solid var(--color-black-secondary);
  border-left: 20px solid var(--color-black-secondary);
}

.success {
  border-top: 20px solid var(--color-success);
  border-left: 20px solid var(--color-success);
}

.icon {
  position: absolute;
  fill: var(--color-white);
  top: 6px;
  left: 4px;
  height: 14px;
}
