.buttonLogout {
  margin-bottom: 16px;
}

.dropdownMenu {
  max-height: 550px;
  overflow-y: auto;
}

.help-icon-container {
  display: flex;
  margin: 0 16px 0 0;
  justify-content: center;
  align-items: center;
}

.help-icon {
  cursor: pointer;
  color: var(--color-primary-light);
}