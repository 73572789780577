.container {
  display: grid;
  grid-template-rows: 200px auto;
}

.brand {
  align-self: center;
  margin: 0 auto;
}

.brand img {
  height: 84px;
  width: auto;
}

.content {
  align-self: center;
  margin: 0 auto 60px auto;
  max-width: 428px;
}
